export interface UseAsyncOptions {
  shouldReThrow?: boolean;
  excludeErrorInAnalytics?: boolean;
}

export enum Gate {
  MagentoInstructions = 'magento_instructions',
  ShouldShowExpertServicesAd = 'should_show_expert_services_ad',
  ScriptsAutoInstalled = 'is_ae_script_automatically_installed',
  PreJSDashboardUpdate = 'pre_js_dashboard_update',
  ShowAccountProfilePage = 'portal_is_account_profile_page_enabled',
  ShouldShowVendastaNavBar = 'portal_show_vendasta_nav_bar',
  ShowUserDropdownInHeader = 'portal_show_user_dropdown_in_header',
  ShowSitesPageAddSiteButton = 'portal_show_sites_page_add_site_button',
  CanManageSubscriptions = 'can_manage_subscription',
  ShouldShowConstructionPage = 'should_show_construction_page',
  ShowLegalSupport = 'show_legal_support',
  ReportingVersionControl = 'reporting_version_control',
  AdminPolicyTableEnabled = 'admin_policy_table_enabled',
  ShouldShowAdminLiveMonitoringEditor = 'should_show_admin_live_monitoring_editor',
  AudioEyeAccounts = 'audioeye_accounts',
  DisableBulkFixerForm = 'disable_bulk_fixer_form',
  ShouldShowAccountSettings = 'should_show_account_settings',
  ShouldShowLegalTemplates = 'should_show_legal_templates',
  RemediationsAiSuggestions = 'remediations_ai_suggestions',
  ShouldShowViewAllSiteRemediationsButton = 'should_show_view_all_site_remediations_button',
  ShouldShowPreInstallDashboard = 'show_pre_install_dashboard',
  ProtectionFeatureEnabled = 'protection_feature_enabled',
  ShouldShowProtectionExperience = 'should_show_protection_experience',
  ShouldShowFixVsRem = 'should_show_rems_to_fixes',
  ChargebeeInvoicesPage = 'chargebee_invoices_page',
  A11yStatementLanguageSelector = 'a11y_statement_language_selector',
  // JOY UI change gates
  ShouldUseNewSiteManagement = 'should_use_new_site_management',
  ShouldUseJoyifiedRemAdmin = 'joyified_rem_admin',
  ShouldShowFocusView = 'rem_admin_overview_focus_view',
  IsSiteSwitcherEnabled = 'is_site_switcher_enabled',
  ShouldUseLiveChargebee = 'should_use_live_chargebee',
  ShowDeveloperDocsLink = 'show_developer_docs_link',
  ShowLogoutLink = 'show_logout_link',
  ShouldShowLegalHelpChanges = 'should_show_legal_help_changes',
  ShouldShowLegalHelp = 'should_show_legal_help',
  ShouldShowStyleRemediationCreateButton = 'should_show_style_remediation_create_button',
  ShouldShowComponentRemediationCard = 'should_show_component_rems_card',
  ShouldShowLegalSupportTab = 'should_show_legal_support_tab',
  OverrideStartedAfterProtectionLaunchDateRestriction = 'override_started_after_launch_date_restriction',
  ShouldShowPreInstallReportsPageUpdates = 'should_show_pre_install_reports_page_updates',
  ShouldShowCustomerIntegrations = 'should_show_customer_integrations',
  ShouldUseA11yPdfGeneratorService = 'should_use_a11y_pdf_generator_service',
  ShouldShowAccountSettingsDialog = 'should_show_account_settings_dialog',
  // Springtime Gates
  ConsentMaxDevelopment = 'consent_max_development_gate',
  // Permanant Gates
  ShouldMockUndefinedReportResponse = 'should_mock_undefined_report_response',
}

export enum DynamicConfig {
  UnderConstructionTime = 'under_construction_time',
  HomepageIssuesByFixTypeMetrics = 'homepage_issues_by_fix_type_metrics',
}
